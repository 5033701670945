import logo from '../../assets/logo.jpg';
import './HomeTemplate.scss';
import { BsFillTelephoneFill, BsFacebook } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Page } from 'react-facebook';
import { useLayoutEffect, useRef, useState } from 'react';

export const HomeTemplate = () => {
  const fbEl = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(-1);

  useLayoutEffect(() => {
    setWidth(fbEl.current?.clientWidth || -1);
  }, [fbEl, setWidth]);

  return (
    <div className="home">
      <div className="info-container">
        <img src={logo} className="logo" alt="Midnight Restorations Logo" />

        <dl>
          <dt>
            <BsFillTelephoneFill title="Phone" />
          </dt>
          <dd>
            <a className="link" href="tel://+447554168168">
              07554 168168
            </a>
          </dd>

          <dt>
            <BsFacebook title="Facebook Page" />
          </dt>
          <dd>
            <a className="link" href="https://www.facebook.com/MidnightRestorationsGarage">Facebook</a>
          </dd>

          <dt>
            <FaMapMarkerAlt title="Address" />
          </dt>
          <dd>
            <a className="link" href="https://goo.gl/maps/j8vaqgDqKpu2uvwr8">
              <address>Aldam Street, DL1 2HY</address>
            </a>
          </dd>
        </dl>
      </div>

      <div className="fb-container" ref={fbEl}>
        {width === -1
          ? null
          : (
            <Page
              href="https://www.facebook.com/MidnightRestorationsGarage"
              tabs="timeline"
              hideCover
              width={Math.max(180, Math.min(500, width))}
              smallHeader
              showFacepile
            />
          )}
      </div>
    </div>
  );
};
